<template>
        <div class="row">
            <div class="col-md-7" v-if="servicio.tramitado == 1">
                <servicio-comunicaciones-form :id="id"/>
            </div>
            <div class="col-md-5" :style="servicio.tramitado != 1? 'justify-self:start':'justify-self:end'">
                <servicio-comunicaciones-lista :mensajes="mensajes" :id="id" :resumen="resumen" @recargarMensajes="cargarmensajes"/>
            </div>
        </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import serviciocomunicacionesform from './comunicaciones_form.vue';
import serviciocomunicacioneslista from './comunicaciones_lista.vue';
export default ({
            props: ['id', 'cargar', 'servicio'],
    setup() {
        return;
    },
    components: {
        'servicio-comunicaciones-form': serviciocomunicacionesform,
        'servicio-comunicaciones-lista': serviciocomunicacioneslista,
    },
      data() {
        return {
            clientedatos: [],
            titulocliente: '',
            mensajes: '',
            tramitado: true,
        }
    },
    methods: {
        async cargarmensajes(tipo) {
            console.log('entratipo', tipo);
            if(this.servicio.tramitado!=1){
                this.tramitado = false;
            }else{
                this.tramitado = true;
            }
            //GET  modulos/pwgsapi/index.php/servicios/:id/mensajes
            const api = new PwgsApi();
            let mensajesaux = [];
            if (tipo && tipo != ''){
                mensajesaux = await api.get('servicios/' + this.$props.id + '/mensajes?'+tipo);
            }else{
                mensajesaux = await api.get('servicios/' + this.$props.id + '/mensajes');          
            }
            this.mensajes = mensajesaux.datos;
            this.mensajes.forEach(element => {
                element.reducido = true;
            });
        },
        
    },
    watch: {
        id() {
            this.cargarmensajes();
         },
         cargar(){
            this.cargarmensajes();
        }
    },
    mounted(){
        setTimeout(async() => {
        this.cargarmensajes();
                    }, 1000); 
        
    },
    activated(){
        this.cargarmensajes();
    }
})



</script>
