<template>

    <div class="card">
        <div class="card-header bg-gray" style="display: flex; justify-content: start; height: 63px;">
            <h3 class="card-title"><i class="fab fa-telegram-plane mr-2"></i>{{ $t('general.enviodemensajes') }}
            </h3>
        </div>
        <div class="card-body">
            <div class="row" v-if="gremios" :class="clasegremio">
                <div class="col-md-12">
                    <table class="table table-striped table-valign-middle">
                        <thead>
                            <tr>
                                <th v-for="columna in gremios.gremios.encabezado" :key="columna">{{ columna }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="filas in gremios.gremios.filas" :key="filas">
                                <td style="padding:0.3rem" v-for="fila in filas" :key="fila">
                                    <div v-if="fila.campos">
                                        <div v-for="campo in fila.campos" :key="campo">
                                            <label style="margin-bottom:0rem;vertical-align:top" class="btn active"
                                                v-if="campo.tipo == 'radio'">
                                                <input class="defecto" v-if="campo.valor == fila.campos[0].valor"
                                                    type="radio"
                                                    @click="inputchecked = campo.valor; tipoComunicacion(campo.valor); guardartoken(campo.nombre, campo.valor); auxtokengremio = campo.valor; selecciondefecto()"
                                                    name="opciones" :id="campo.valor" autocomplete="off" />
                                                <input style="margin-bottom:0rem;vertical-align:top" v-else type="radio"
                                                    @click="inputchecked = campo.valor; tipoComunicacion(campo.valor); guardartoken(campo.nombre, campo.valor); auxtokengremio = campo.valor; selecciondefecto()"
                                                    name="opciones" :id="campo.valor" autocomplete="off" />
                                                {{ campo.desc }}
                                            </label>
                                            <label class="btn active" v-else-if="campo.desc && campo.tipo == 'button'">
                                                <button style="font-size:0.7em" type="button"
                                                    v-if="campo.desc == 'Actualizar'"
                                                    @click="actualizar(filas[0].columna1.campos[0].desc);"
                                                    class="btn btn-secondary">
                                                    <i class="fas fa-sync"> {{ campo.desc }}</i>
                                                </button>
                                            </label>
                                        </div>
                                    </div>
                                    <div v-if="!fila.campos">
                                        <p style="margin-bottom: 0px;" v-html=" fila"></p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="spinner == true" class="spinners"></div>
            <div :class="spinner == true ? 'difuminado' : ''">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="col-md-6">
                                <label for="exampleInputEmail1">{{ $t('general.tipodeenvio') }}</label><br>
                                <select @change="auxenviosdos = selected_tipo_envio; this.comprobarconsulta(this.auxtokengremio, this.selected_tipo_envio.tipo_mensaje,'refrescar')
                                    " class="custom-select form-control-sm exampleInputEmail1 select2"
                                    v-model="selected_tipo_envio">
                                    <option :title="mensajes.title" :disabled="mensajes.disabled == 1"
                                        :selected="comunicaciones[0].nombre == mensajes.nombre"
                                        v-for="mensajes in comunicaciones" :key="mensajes.nombre" :value="mensajes">
                                        {{ mensajes.nombre }}
                                    </option>
                                </select>
                                <div v-if="selected_tipo_envio && selected_tipo_envio.title">
                                    <p>{{ selected_tipo_envio.title }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" v-if="selected_tipo_envio">
                        <div v-if="selected_tipo_envio.tipo_mensaje == 'responder_dialogo'">
                            <div class="col-md-12">
                                <table class="table table-striped table-valign-middle">
                                    <thead>
                                        <tr>
                                            <th v-for="columna in gremios.comunicaciones.encabezado" :key="columna">{{
                                                columna }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="filas in gremios.comunicaciones.filas" :key="filas">
                                            <td v-for="fila in filas" :key="fila">
                                                <div v-if="fila.campos">
                                                    <div v-for="campo in fila.campos" :key="campo">
                                                        <label class="btn active" v-if="campo.tipo == 'radio'">
                                                            <input class="defecto"
                                                                @click="auxcheck = campo.valor; comprobarconsulta(auxtokengremio, campo.nombre, campo.valor); resp_dialogo = campo.valor; test123()"
                                                                v-if="campo.valor == fila.campos[0].valor" type="radio"
                                                                name="opciones2" :id="campo.valor" autocomplete="off" />
                                                            <input v-else type="radio"
                                                                @click="auxcheck = campo.valor; comprobarconsulta(auxtokengremio, campo.nombre, campo.valor); resp_dialogo = campo.valor; test123()"
                                                                name="opciones2" :id="campo" autocomplete="off" />
                                                            {{ campo.desc }}
                                                        </label>
                                                        <label class="btn active"
                                                            v-else-if="campo.desc && campo.tipo == 'button'">

                                                        </label>
                                                    </div>
                                                </div>
                                                <div v-if="!fila.campos">
                                                    <p v-html="fila"></p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="form-group">
                            <div v-for="campo in selected_tipo_envio.campos" :name="campo.desc" :class="campo.desc"
                                :key="campo.desc" :style="comprobarestilo(campo.tipo,campo.desc)">
                                <!--v-if="!campo[1]" -->
                                <div
                                    v-if="campo.tipo == 'select' || campo.tipo == 'radio' || campo.tipo == 'checkbox' || campo.tipo == 'text' ">
                                    <!--<label v-if="campo.tipo != 'checkbox'" :for="campo.nombre">{{campo.desc}}</label><br>-->
                                    <label v-if="campo.tipo != 'checkbox' && campo.tipo != 'hidden' && campo.desc"
                                        :for="campo.nombre">{{ campo.desc }}</label><br
                                        v-if="campo.tipo != 'checkbox' && campo.tipo != 'hidden' && campo.desc"><!--333-->
                                    <label v-if="campo.tipo != 'checkbox' && campo.tipo != 'hidden' && !campo.desc"
                                        :for="campo.nombre">{{ campo.nombre }}</label><br
                                        v-if="campo.tipo != 'checkbox' && campo.tipo != 'hidden' && !campo.desc"><!--444-->
                                    <select @change="comprobarconsulta(auxtokengremio, campo.nombre, campo.valor, true)"
                                        v-if="campo.tipo == 'select'"
                                        :class="'custom-select form-control ' + campo.nombre" v-model="campo.valor">
                                        <option value="" selected hidden>Seleccione opción</option>
                                        <option :disabled="campo.disabled == 'disabled'" v-for="opcion in campo.valores"
                                            :key="opcion.valor" :value="opcion.valor">{{ opcion.texto }}</option>
                                        <input type="radio" v-model="campo.valor" :id="campo.desc"
                                            autocomplete="off" /><span
                                            v-if="campo.tipo == radio">{{campo.valor}}</span>&nbsp;&nbsp;
                                    </select>

                                    <div v-if="campo.desc == 'Min.'">
                                        <div class="flex-br"></div>
                                    </div>
                                    <div v-if="campo.tipo == 'checkbox'">
                                        <label :for="campo.desc">{{ campo.desc }}&nbsp;&nbsp;</label>
                                        <input
                                            @change="comprobarconsulta(auxtokengremio, campo.nombre, campo.valor, 'tipoinput')"
                                            :disabled="mensajes && mensajes.disabled == 'disabled'" type="checkbox"
                                            :id="campo.desc" :class="'custom-checkbox ' + campo.nombre"
                                            :checked="campo.checked" />
                                    </div>
                                    <input :disabled="campo.disabled == 'disabled'" v-if="campo.tipo == 'text'"
                                        type="text" v-model="campo.valor" :class="'form-control ' + campo.nombre" />
                                </div>
                                <div v-if="campo.tipo == 'img'">
                                    <label>Firma:</label>
                                    <img :src="campo.src" style="width:150px">
                                </div>
                                <div v-if="campo.tipo == 'textarea'" style="display:block">
                                    <label :for="campo.desc" style="margin-bottom:1.2rem">{{ campo.desc
                                        }}&nbsp;&nbsp;</label>

                                    <span v-if="campo.tipo == 'textarea' && campo.editor_html == '1'">
                                        <Editor :id="campo.nombre" @input="sacarlongitud(campo.nombre)"
                                            @keydown="onKeyDown($event, campo.valor, campo.condiciones.maxLength)"
                                            editorStyle="height: 160px" v-model="campo.valor" v-if="campo.condiciones">
                                        </Editor>
                                        <Editor :id="campo.nombre" @input="sacarlongitud(campo.nombre)"
                                            @keydown="onKeyDown($event, campo.valor, campo.condiciones.maxLength)"
                                            editorStyle="height: 160px" v-model="campo.valor" v-else>
                                        </Editor>
                                        <label v-if="campo.condiciones && campo.condiciones.maxLength">{{
                                            longitud[campo.nombre] }}/{{ campo.condiciones.maxLength }}
                                            Caracteres</label>
                                    </span>
                                    <span v-if="campo.tipo == 'textarea' && campo.editor_html != '1'">
                                        <textarea :id="campo.nombre" @input="sacarlongitud(campo.nombre)"
                                            @keydown="onKeyDown($event, campo.nombre, campo.condiciones.maxLength)"
                                            @click="console.log(campo)" :disabled="campo.disabled == 'disabled'"
                                            v-if="campo.condiciones && campo.condiciones.maxLength"
                                            :modelValue="campo.valor" v-model="campo.valor"
                                            :class="'form-control ' + campo.nombre"></textarea>
                                        <textarea :id="campo.nombre" @input="sacarlongitud(campo.nombre)"
                                            @keydown="onKeyDown($event, campo.nombre, campo.condiciones.maxLength)"
                                            @click="console.log(campo)" v-model="campo.valor"
                                            :disabled="campo.disabled == 'disabled'" v-else :modelValue="campo.valor"
                                            :class="'form-control ' + campo.nombre"></textarea>
                                        <label v-if="campo.condiciones && campo.condiciones.maxLength">
                                            {{ longitud[campo.nombre] }} /{{ campo.condiciones.maxLength }} Caracteres
                                        </label>
                                    </span>
                                </div>
                            </div>
                            <div v-if="anteriormin==true"><br></div>

                        </div>
                        <div class="form-group">
                            <div v-for="campo in selected_tipo_envio.campos" :key="campo.desc">
                                <div class="col-md-12" v-if=" Object.keys(campo) == 'documentos' || campo.documentos ">
                                    <div v-if="Object.keys(campo) == 'documentos' && campo.documentos[1]">
                                        <!-- <label :for="campo.documentos[1].nombre">{{ campo.documentos[1].desc }}</label><br>
                                <select v-if="Array.isArray(campo.documentos[0].valores)" :class="'custom-select form-control ' + campo.documentos[1].nombre" v-model="campo.documentos[0].valor">
                                        <option v-for="opcion in campo.documentos[0].valores" :key="opcion.valor" :value="opcion.valor">{{ opcion.texto }}</option>
                                    </select><br><p></p>
                                    <select v-if="!Array.isArray(campo.documentos[0].valores)" :class="'custom-select form-control ' + campo.documentos[1].nombre" v-model="campo.documentos[0].valor">
                                        <option :value="campo.documentos[0].valores.valor">{{ campo.documentos[0].valores.texto }}</option>
                                    </select><br><p></p>-->
                                        <FileUpload mode="basic" multiple="true" accept="image/*" id="asd"
                                            chooseLabel="Subir Archivo" :maxFileSize="50000000"
                                            @select="nombrefichero($event); campo.documentos[1].valor = nombrearchivo" />
                                        <br>
                                        <Button @click="cargardocumentos()" type="button" class="btn btn-info">{{
                                            $t('general.documentos') }}</button>
                                        <br><!--111-->
                                        <div class="col-md-6">{{ campo.documentos[1].valor }}<Button
                                                v-if="campo.documentos[1].valor" @click="campo.documentos[1].valor = ''"
                                                class="p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                                type="button" aria-label="Cancel" data-pc-name="button"
                                                data-pc-severity="danger"><span class="p-button-icon pi pi-times"
                                                    data-pc-section="icon"></span></button>
                                        </div>
                                        <Dialog v-show="mostrardialogo" header="Documentos"
                                            v-model:visible="mostrardialogo" modal :style="{ width: '80vw' }">
                                            <div
                                                style="display: grid; grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); gap: 10px;">
                                                <div v-for="documento in documentos" :key="documento.id_documento"
                                                    style="margin-left:40px;display: flex; flex-direction: column; align-items: center; justify-content: space-between; height: 220px;">
                                                    <select @change="asociardocumento(documento)"
                                                        v-if="Array.isArray(campo.documentos[0].valores)"
                                                        :class="'custom-select form-control ' + campo.documentos[0].nombre"
                                                        v-model="documento.valor"
                                                        style="width: fit-content; height: 30px;">
                                                        <option :value="documento.valor" disabled selected hidden>
                                                            Seleccione</option>
                                                        <option v-for="opcion in campo.documentos[0].valores"
                                                            :key="opcion.valor" :value="opcion.valor">
                                                            {{ opcion.texto }}
                                                        </option>
                                                    </select>
                                                    <select @change="asociardocumento(documento)"
                                                        v-if="!Array.isArray(campo.documentos[0].valores)"
                                                        :class="'custom-select form-control ' + campo.documentos[0].nombre"
                                                        v-model="documento.valor"
                                                        style="width: fit-content; height: 30px;">
                                                        <option :value="documento.valor" disabled selected hidden>
                                                            Seleccione</option>
                                                        <option v-for="opcion in campo.documentos[0].valores"
                                                            :key="opcion.valor" :value="opcion.valor">
                                                            {{ opcion.texto }}
                                                        </option>
                                                    </select>
                                                    <div v-if="documento.cuando">
                                                        <span style="width: 150px;" v-if="documento.cuando=='despues'"
                                                            class="badge bg-blue">después</span>
                                                        <span style="width: 150px;" v-else class="badge bg-blue">{{
                                                            documento.cuando }}</span>
                                                    </div>
                                                    <img style="width: 150px; height: 160px;"
                                                        v-if="urlglobal != 'null.pwgs.app'"
                                                        :src='documento.url_completa_descarga' width="150" preview
                                                        :title="documento.descripcion" />
                                                    <img style="width: 150px; height: 160px;"
                                                        v-if="urlglobal == 'null.pwgs.app'"
                                                        :src='"https://desarrollo.pwgs.app/" + documento.url_descarga'
                                                        width="150" preview :title="documento.descripcion" />
                                                </div>
                                            </div>
                                        </Dialog>
                                    </div>
                                </div>
                                <div v-if="Object.keys(campo) == 'documentos' && !campo.documentos[1]">
                                    <br>
                                    <Button @click="cargardocumentos()" type="button">{{ $t('general.documentos')
                                        }}</button>
                                    <!--<FileUpload mode="basic" multiple="true" accept="image/*" id="asd" chooseLabel="Subir Archivo" :maxFileSize="50000000" @select="nombrefichero($event); campo.documentos[0].valor = nombrearchivo" />-->
                                    <br>
                                    <div class="col-md-6">{{ campo.documentos[0].valor }}<Button
                                            v-if="campo.documentos[0].valor" @click="campo.documentos[0].valor = ''"
                                            class="p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                            type="button" aria-label="Cancel" data-pc-name="button"
                                            data-pc-severity="danger"><span class="p-button-icon pi pi-times"
                                                data-pc-section="icon"></span></button>
                                    </div>
                                    <Dialog v-show="mostrardialogo" v-model:visible="mostrardialogo" modal
                                        :style="{ width: '50vw' }">
                                        <div class="col-md-12" v-for="documento in documentos"
                                            :key="documento.id_documento">
                                            <Image :src="documento.url_completa_descarga" width="250" preview />
                                        </div>
                                    </Dialog>
                                </div>
                                <!--archivoarrellenar-->
                                <div v-if="campo[0]">
                                    <div v-if="campo[0].tipo == 'radio'">
                                        <input type="radio" v-model="campo[0].valor" :id="campo[0].desc"
                                            autocomplete="off" />&nbsp;&nbsp;{{ campo[0].desc }}
                                    </div>
                                </div>
                            </div>
                            <ul style="list-style-type: none;">
                                <li v-for="documento in documentosanyadidos" :key="documento.id_documento">
                                    <p><button @click="eliminardocumento(documento)"
                                            class="p-button p-component p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                            type="button" aria-label="Cancel" data-pc-name="button"
                                            data-pc-severity="danger" data-pc-section="root"><span
                                                class="p-button-icon pi pi-times"
                                                data-pc-section="icon"></span></button>{{
                                        documento.documento }}&nbsp;({{
                                        documento.valor }})</p>
                                </li>
                            </ul>
                            <div class="col-md-12">
                                <button class="btn btn-success btn-block" @click="enviarMensaje()" type="button">{{
                                    $t('general.enviar') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Editor from 'primevue/editor';
import { PwgsApi } from '../../../../services/PwgsApi';
//import FileUpload from 'primevue/fileupload';
import Dialog from 'primevue/dialog';
import Image from 'primevue/image';
import Button from 'primevue/button';
import $ from 'jquery';

export default ({
    props: ['id'],
    components: {
        Dialog, Image, Button, Editor,
    },
    setup() {
        return;
    },
    data() {
        return {
            selected_tipo_envio: '',
            tipo_comunicacion: '',
            comunicaciones: [],
            nombrearchivo: [],
            gremios: '',
            columna1: "columna1",
            token: '',
            archivos: [],
            miestilo: 'display: inline-flex;',
            documentos: [],
            mostrardialogo: false,
            archivoasubir: '',
            inputchecked:'',
            urlglobal: '',
            tipocom: '',
            auxenvios: '',
            auxtokengremio: '',
            campospendientesconsulta: [],
            documentosanyadidos: [],
            nombrecampo: '',
            valorcampo: '',
            resp_dialogo: '',
            testenvios: '',
            auxenviosdos:'',
            anteriormin:false,
            miestilodos: 'margin-top:10px;margin-right:10px',
            auxform: {},
            spinner: false,
            auxcheck:'',
            clasegremio: 'difuminadodos',
            seleccionado: false,
            longitud:[]
        }
    },
    methods: {
        
        test123() {
            /*console.log("comuprueba", this.comunicaciones);
            console.log("inputchecked", document.getElementById(this.inputchecked));
            document.getElementById(this.inputchecked).checked = true;
            document.getElementById(this.auxcheck).checked = true;*/
            setTimeout(() => {
                for (let i = 0; i < this.comunicaciones.length; i++) {
                    if (this.comunicaciones[i].tipo_mensaje == 'responder_dialogo') {
                        console.log("hola");
                        this.selected_tipo_envio = this.comunicaciones[i];
                    }
                }
            }, 5000);

          
        },
        sacarlongitud(texto) {
            this.longitud[texto] = document.getElementById(texto).value.length;

            
        },
        onKeyDown(evt, texto, maximocaracteres) {
            console.log("idtext", $('#' + texto));
            if(maximocaracteres){
                if (document.getElementById(texto).value.length >= maximocaracteres) {
                    if (evt.keyCode >= 48 && evt.keyCode <= 90) {
                        evt.preventDefault()
                        return
                    }
                }
            }
        },
        async enviarMensaje() {
            //POST  modulos/pwgsapi/index.php/servicios/:id/comunicacion-sistema
            const api = new PwgsApi();
            var subidadatos = {};
            let documentos = "documentos";
            let tipodemensaje = "tipo_mensaje";
            let campos = "campos";
            let dialogo_generali = "dialogo_generali";
            let auxdatos = {};

            for (let i = 0; i < this.selected_tipo_envio.campos.length; i++) {
                let campoActual = this.selected_tipo_envio.campos[i];

                if (campoActual.documentos) {
                    subidadatos[documentos] = []; // Se inicializa correctamente como array

                    for (let j = 0; j < this.documentosanyadidos.length; j++) {
                        subidadatos[documentos].push({
                            tipo_archivo: this.documentosanyadidos[j].valor,
                            nombre_documento: this.documentosanyadidos[j].documento
                        });
                    }
                } else {
                    if (campoActual.valor) {
                        subidadatos[campoActual.nombre] = campoActual.valor;
                    } else if (Array.isArray(campoActual) && campoActual[0] && campoActual[0].tipo !== "hidden") {
                        subidadatos[campoActual[0].nombre] = campoActual[0].valor;
                    }
                }
            }
            auxdatos[tipodemensaje] = this.selected_tipo_envio.tipo_mensaje;
            auxdatos[campos] = subidadatos;

            if (this.selected_tipo_envio.tipo_mensaje == "responder_dialogo") {
                auxdatos[dialogo_generali] = this.resp_dialogo;
            }
            if (this.nombrecampo) {
                auxdatos[this.nombrecampo] = this.valorcampo;
            }
            try {
                await api.post('servicios/' + this.$props.id + '/comunicacion-sistema', auxdatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Mensaje enviado correctamente', life: 2000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                setTimeout(() => {
                    //document.getElementsByClassName("defecto")[0].click();
                }, 500);
                this.spinner = false;
            }
            this.selected_tipo_envio = '';
            this.$parent.cargarmensajes();
            this.comprobarconsulta(this.auxtokengremio, this.selected_tipo_envio.tipo_mensaje, 'refrescar');
        },
        guardartoken(nombre, valor) {
            this.nombrecampo = nombre;
            this.valorcampo = valor;
        },
        comprobadchecked(campo) {
            if(campo == "checked"){
                return true;
            }
            else{
                return false;
            }
        },
        async actualizar(idipas) {
            //index.php/servicios/:id/prestacion-ipas/:n_prestacion_ipas
            const api = new PwgsApi();
            if (confirm('¿Desea actualizar la prestación ' + idipas + '?')) {
                try {
                    await api.put('servicios/' + this.$props.id + '/prestacion-ipas/' + idipas);
                }
                catch (e) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
                    setTimeout(() => {
                        document.getElementsByClassName("defecto")[0].click();
                    }, 500);
                    this.spinner = false;
                }
                this.tipoComunicacion();
                this.cargarGremios();
            }
        },
        /*    nombrefichero(event) {
                // POST  modulos/pwgsapi/index.php/servicios/:id/guardar-documento
                this.getBase64(event.files[0],this.$props.id);
                console.log("archivoasubir", this.archivoasubir);
                this.nombrearchivo = event.files[0].name;
                let aux = { nombre: event.files[0].name, url: event.files[0].objectUrl, tipo: event.files[0].type };
                this.archivos.push(aux);
                console.log("evento", event);
            },*/
        async cargardocumentos() {
            this.urlglobal = localStorage.getItem('direccion');
            // GET:  modulos/pwgsapi/index.php/servicios/:id/documentos
            const api = new PwgsApi();
            try {
                this.documentos = await api.get('servicios/' + this.$props.id + '/documentos');
            }
            catch (e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
                setTimeout(() => {
                    document.getElementsByClassName("defecto")[0].click();
                }, 500);
                this.spinner = false;
            }
            this.mostrardialogo = true;
        },
        asociardocumento(archivo) {
            console.log("archivo", archivo);
            this.documentosanyadidos.push(archivo);
        },
        test() {
            this.clasegremio = "difuminadodos";

            setTimeout(() => {
                document.getElementsByClassName("defecto")[0].click();
                this.clasegremio = "";
            }, 500);


        },
        comprobarconsulta(tokengremio, tipomensaje, valormensaje, tipoinput) {
            console.log("entraacomprobar");
            if ((tipoinput == "tipoinput" && tipoinput == 1) || !tipoinput || tipoinput ==true) {
                let auxmostrar = false;
                if (this.campospendientesconsulta[tipomensaje] == true) {
                    auxmostrar = true;
                }
                if (this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje] && this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos_pendientes_seleccionar) {
                    for (let i = 0; i < this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos_pendientes_seleccionar.length; i++) {
                        if (this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos_pendientes_seleccionar[i] == tipomensaje);
                        auxmostrar = true;
                    }
                }
                if (auxmostrar == true) {
                    this.campospendientesconsulta[tipomensaje] = true;
                    this.tipoComunicacion(tokengremio, tipomensaje, valormensaje,tipoinput)
                }
                if (valormensaje  == "refrescar") { 
                    this.campospendientesconsulta[tipomensaje] = true;
                    this.tipoComunicacion(tokengremio, tipomensaje, valormensaje, tipoinput)
                }
            }
            if (this.selected_tipo_envio.campos) {
                const dataArr = new Set(this.selected_tipo_envio.campos);

                this.selected_tipo_envio.campos = [...dataArr];
            }

        },
        eliminardocumento(doc) {
            this.documentosanyadidos.splice(this.documentosanyadidos.indexOf(doc), 1);
        },
        async tipoComunicacion(tokengremio, tipomensaje, valormensaje,tipoinput) {
            this.comunicaciones = [];
            this.auxform.tipo_mensaje = this.selected_tipo_envio.tipo_mensaje;
            
            this.auxform[tipomensaje] = valormensaje;
            this.spinner = true;
            this.auxform.id_gremio = tokengremio;
            console.log("auxform", this.auxform);
            //post  modulos/pwgsapi/index.php/servicios/:id/comunicaciones-sistema
            this.urlglobal = localStorage.getItem('direccion');
            const api = new PwgsApi();
            this.tipocom = tokengremio;
            if (this.auxenvios && this.selected_tipo_envio) {
                console.log("auxenvios123", this.auxenvios);
               // this.selected_tipo_envio.campos = this.auxenvios;
            }
            this.selected_tipo_envio = this.auxenviosdos;
            console.log("auxenviosdos", this.auxenviosdos);
            console.log("auxform2", this.auxform);
            if (tokengremio || tipoinput == true) {

                let subidadatos = { id_gremio: tokengremio }
                if (tipomensaje && !valormensaje) {
                    subidadatos.tipo_mensaje = tipomensaje
                }
                if (tipomensaje && valormensaje) {
                    subidadatos.tipo_mensaje = this.selected_tipo_envio.tipo_mensaje;
                    console.log("tipomensaje", tipomensaje);
                    subidadatos[tipomensaje] = valormensaje;
                    console.log("subidadatos", subidadatos);
                    console.log("test666", this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos_pendientes_seleccionar);
                }
                try{

                    if (tipoinput == true) {
                        this.tipo_comunicacion = await api.post('servicios/' + this.$props.id + '/comunicaciones-sistema', this.auxform);
                        console.log("cargado1");
                        this.spinner = false;
                    }
                    else{
                        this.tipo_comunicacion = await api.post('servicios/' + this.$props.id + '/comunicaciones-sistema', subidadatos);
                        console.log("cargado2");
                        this.spinner = false;

                    }
                    console.log("aqui123", this.auxenviosdos);
                    this.selected_tipo_envio = this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje];
                    this.selected_tipo_envio = this.auxenviosdos;

                /*    if (this.selected_tipo_envio.campos) {
                        const dataArr = new Set(this.selected_tipo_envio.campos);

                        this.selected_tipo_envio.campos = [...dataArr];
                    }
                    if (this.selected_tipo_envio && this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje]) {
                        this.selected_tipo_envio = this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje]
                    }
                    console.log("aqui123prueba", this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje]);*/
                    

                }
                catch (e) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
                    setTimeout(() => {
                        //document.getElementsByClassName("defecto")[0].click();
                    }, 500);
                    this.spinner = false;
                }
                console.log("tipocom", this.tipo_comunicacion);
                console.log("valormensaje", valormensaje);
                if (this.selected_tipo_envio && tipomensaje && !valormensaje) {
                    console.log("selectenvio", this.selected_tipo_envio);
                    console.log("asdf123", this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje]);
                    this.selected_tipo_envio.campos = this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos;
                }
            }
            else {

                this.tipo_comunicacion = await api.post('servicios/' + this.$props.id + '/comunicaciones-sistema');
                this.spinner = false;
                console.log("cargado3");
            }
            this.comunicaciones = [];
            for (const [tipo, nombre] of Object.entries(this.tipo_comunicacion.tipos_mensajes)) {
                let datos = { tipo_mensaje: tipo, nombre: nombre.nombre, campos: nombre.campos, title: nombre.title, disabled: nombre.disabled };
                console.log("tipopau", tipo);
                console.log("nombrepau", nombre);
                this.comunicaciones.push(datos);
            }//*****AQUI/***/
            /*  let aux = this.comunicaciones;
                for (let i = 0; i < this.comunicaciones.length; i++){
                let repetido = 0;
                for (let j = 0; j < aux.length; j++){
                    if (this.comunicaciones[i].nombre == aux[j].nombre) {
                        repetido++;
                        this.comunicaciones[i].repetido == repetido;
                    }
                }
            }*/
            if (valormensaje) {
                this.selected_tipo_envio.campos = [];
                for (let i = 0; i < this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos.length; i++) {
                    let repetidoaux = false;
                    console.log("test1", this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos[i]);
                    for (let j = 0; j < this.selected_tipo_envio.campos.length; j++) {
                        if (this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos[i] == this.selected_tipo_envio.campos[j]) {
                            repetidoaux = true;
                            console.log("repetido");
                        }
                        console.log("test2", this.selected_tipo_envio.campos[j]);
                    }
                    if (repetidoaux == false) {
                        this.selected_tipo_envio.campos.push(this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos[i])
                    }
                }
            }
            const dataArr = new Set(this.comunicaciones);

            this.comunicaciones = [...dataArr];
            console.log("comunicaciones333", this.comunicaciones);
            
            if (this.selected_tipo_envio) {
                const dataArr = new Set(this.selected_tipo_envio.campos);

                this.selected_tipo_envio.campos = [...dataArr];
            }
            console.log("auxenvios321", this.auxenvios);
            this.selecciondefecto();

        },
        async cargarGremios() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/comunicaciones-gremios
            this.gremios = '';
            const api = new PwgsApi();
            try {
                this.gremios = await api.get('servicios/' + this.$props.id + '/comunicaciones-gremios');
            }
            catch (e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
                

            }

        },
        comprobarestilo(campotipo, campodesc) {
            //[campo.tipo != 'checkbox' || campo.tipo != 'radio' || campo.desc.startsWith('Fecha')
            //miestilo: 'display: inline-flex;', miestilodos: 'margin-top:10px;margin-right:10px',
            //console.log("campodesc2222", document.getElementsByClassName("Min."));
            if(campodesc=="Min."){
                this.anteriormin == true;

            }
            if (campodesc != "Min.") {
                this.anteriormin == false;
            }
            if (campotipo == 'radio' || campotipo == 'checkbox') {
                return 'margin-top:10px;margin-right:10px';
            }
            else {
                if (campotipo == 'textarea') {
                    return 'display: unset;margin-top:10px;margin-right:10px'

                }
                return 'display: inline-flex;margin-top:10px;margin-right:10px'
            }
        },
        modificarselected() {
            //console.log("tipo_envio", this.selected_tipo_envio.tipo_mensaje);
            if (this.selected_tipo_envio)  this.tipoComunicacion(this.tipocom, this.selected_tipo_envio.tipo_mensaje);
        },
        obtenerdisabled(valor) {
            if (valor) {
                return true;
            }
            else return false;
        },

        selecciondefecto() {
            setTimeout(() => {
                console.log("comunicaciones", this.comunicaciones);
                console.log("envio_defecto", this.tipo_comunicacion.datos.envio_defecto);
                let token = false;
                for (let i = 0; i < this.comunicaciones.length; i++) {

                    if (this.comunicaciones[i].nombre == this.tipo_comunicacion.datos.envio_defecto && this.seleccionado == false) {
                        this.selected_tipo_envio = this.comunicaciones[i];
                        token = true;
                        this.seleccionado = true;
                    }  

                    if (this.comunicaciones[i].tipo_mensaje == this.tipo_comunicacion.datos.envio_defecto && this.seleccionado == false) {
                        this.selected_tipo_envio = this.comunicaciones[i];
                        token = true;
                        this.seleccionado = true;
                    }         
                    /*if (this.comunicaciones[i].nombre == "INFO" && seleccionado == false) {
                        this.selected_tipo_envio = this.comunicaciones[i];
                        token = true;
                        seleccionado = true;
                    }
                    if (this.comunicaciones[i].tipo_mensaje == "envioIPAS" && seleccionado == false) {
                        this.selected_tipo_envio = this.comunicaciones[i];
                        token = true;
                        seleccionado = true;
                    }
                    if (this.comunicaciones[i].tipo_mensaje == "INFOGENERALENVIAR" && seleccionado == false) {
                        this.selected_tipo_envio = this.comunicaciones[i];
                        token = true;
                        seleccionado = true;
                    } */
                         
                }
                console.log("token",token);
            }, 2500);
        },
    },

    mounted() {
        this.tipoComunicacion();
        this.cargarGremios();

    },
    watch: {
        id() {
            this.seleccionado = false;

            this.gremios = [];
            this.comunicaciones = [];
            this.documentosanyadidos = [];
            this.tipoComunicacion();
            this.cargarGremios();
            this.selected_tipo_envio = [];

        },
        tipo_comunicacion(){
            this.selecciondefecto();

        },
        comunicaciones() {

        },
        gremios() {
            setTimeout(() => {
                if (this.gremios && document.getElementsByClassName("defecto")[0]) {
                    this.test();
                }
            }, 1000);
        },
        
        selected_tipo_envio() {
            console.log("selected123", this.selected_tipo_envio);
            
            if (this.selected_tipo_envio && this.selected_tipo_envio.tipo_mensaje != "responder_dialogo"){
                this.comprobarconsulta(this.auxtokengremio, this.selected_tipo_envio.tipo_mensaje)
            }
            if (this.selected_tipo_envio && this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje] &&  this.selected_tipo_envio.tipo_mensaje != "responder_dialogo"){
            for (let i = 0; i < this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos.length; i++) {
                let repetidoaux = false;
                console.log("test1", this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos[i]);
                for (let j = 0; j < this.selected_tipo_envio.campos.length; j++) {
                    if (this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos[i] == this.selected_tipo_envio.campos[j]) {
                        repetidoaux = true;
                        console.log("repetido");
                    }
                    console.log("test2", this.selected_tipo_envio.campos[j]);
                }
                if (repetidoaux == false) {
                    this.selected_tipo_envio.campos.push(this.tipo_comunicacion.tipos_mensajes[this.selected_tipo_envio.tipo_mensaje].campos[i])
                }
            }
            }
            
        },
        

    },
    computed: {
        lengthva(valor) {
            if (valor && document.getElementById(valor)) {
                return document.getElementById(valor).value.length
            }
            else return 0;
        },
    }
})
</script>
<style>
.break {
    flex-basis: 100%;
    height: 0;
}
.flex-br {

    width: 100%;
    height: 0;

}
.difuminado {
    filter: blur(6px);
    pointer-events: none;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}
.difuminadodos{
    filter: blur(6px);
        pointer-events: none;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
}
.spinners {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #d9e3ff;
    border-right-color: #007bff;
    animation: spinner-d3wgkg 1s infinite linear;
    position: absolute;
    z-index: 1000;
    top: 70% !important;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;

}

@keyframes spinner-d3wgkg {
    to {
        transform: rotate(1turn);
    }
}
</style>